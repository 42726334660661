@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
    --qualimenti-color: #ae3335;
    --qualimenti-color-brown: rgb(58,40,33);
}

body {
    font-family: 'Poppins', sans-serif;
    padding: 0;
    margin: 0;
    /* background-color: rgb(223,219,218); */
    background-color: #F5F5F5;
}

/* Styles components */
.btn.red {
    background-color: var(--qualimenti-color);
}
.header-icons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
}
.btn.red:hover {
    background-color: #962d2f;
}
.bg-qualimenti {
    background-color: var(--qualimenti-color);
}
.text-bg-qualimenti {
    color: #fff!important;
    background-color: var(--qualimenti-color);
}
.text-bg-qualimenti-disabled {
    color: #fff!important;
    background-color: #E1E1E1;
}
.card {
    border: none;
    /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
}
.form-control:focus {
    border-color: var(--qualimenti-color);
    outline: 0;
    box-shadow: 0 0 0 .25rem var(--qualimenti-color)2c;
}

.text-qualimenti {
    color: var(--qualimenti-color);
}

.text-brown {
    color: var(--qualimenti-color-brown);
}

.btn-qualimenti {
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--qualimenti-color);
    --bs-btn-border-color: var(--qualimenti-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--qualimenti-color);
    --bs-btn-hover-border-color: var(--qualimenti-color);
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--qualimenti-color);
    --bs-btn-active-border-color: var(--qualimenti-color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--qualimenti-color);
    --bs-btn-disabled-border-color: var(--qualimenti-color);
}
.btn-qualimenti-outlined {
    --bs-btn-color: var(--qualimenti-color);;
    --bs-btn-bg: transparent;
    --bs-btn-border-color: var(--qualimenti-color);
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--qualimenti-color);
    --bs-btn-hover-border-color: var(--qualimenti-color);
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--qualimenti-color);
    --bs-btn-active-border-color: var(--qualimenti-color);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--qualimenti-color);
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: var(--qualimenti-color);
}
.text-red {
    color: var(--qualimenti-color);
}
.menu-item {
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
}
button.szh-menu-button {
    border: none;
    background: none;
    color: inherit;
    padding: initial;
}
.wrapper {
    position: relative;
    max-width: 1252px;
    margin: 0 auto;
}
.favorite {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 1.3em;
    color: var(--qualimenti-color);
    cursor: pointer;
}
.favorite-star {
    color: var(--qualimenti-color);
    cursor: pointer;
}
button.btn.btn-search-close {
    border: none;
    background-color: #ffffff85;
    display: flex;
    align-items: center;
    justify-content: center;
}
.search-control::placeholder {
    color: #fff;
}
.form-check-input:checked {
    background-color: var(--qualimenti-color);
    border-color: var(--qualimenti-color);
}
ul.filter {
    list-style-type: none;
    padding: 0;
}
textarea:focus,
input[type="checkbox"]:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border-color: var(--qualimenti-color)7f;
  box-shadow: 0 1px 1px var(--qualimenti-color)7f inset, 0 0 8px var(--qualimenti-color)7f;
  outline: 0 none;
}

.cart-container, .category-menu-container {
    position: relative;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    color: var(--qualimenti-color);
    background-color: #fff;
    margin-right: 10px;
    border-radius: 60px;
    cursor: pointer;
    z-index: 100;
}
.category-menu-container.inverted {
    background-color: var(--qualimenti-color);
    color: #fff;
}
.category-menu-container.outlined {
    color: var(--qualimenti-color);
    background-color: #fff;
    border: 1px solid var(--qualimenti-color);
}
.mobile-menu.show {
    width: 320px!important;
}
.cart-quantity {
    position: absolute;
    width: 25px;
    height: 25px;
    font-size: 13px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 35px;
    top: -5px;
}
@media screen and (max-width: 768px) {
    .cart-container, .category-menu-container {
        min-width: 40px;
        width: 40px;
        height: 40px;
    }
    .cart-quantity {
        width: 15px;
        height: 15px;
        font-size: 10px;
        left: 20px;
        top: -3px;
    
    }
}
.text-right {
    text-align: right;
}

.home-tab-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
}
.home-tab-item.active {
    border-bottom: 2px solid var(--qualimenti-color);
}
.sidebar-menu-item, .sidebar-menu-item:hover, .sidebar-menu-item:focus {
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    color: inherit;
    text-decoration: none;
    font-size: 1.2em;
    margin-bottom: 15px;
    width: fit-content;
    text-align: start;
    padding-top: 0;
    padding-bottom: 0;
}
.pointer {
    cursor: pointer;
}

.list-group-item.active {
    z-index: 2;
    color: white;
    background-color: var(--qualimenti-color);
    border-color: var(--qualimenti-color);
}

.horizontal-slider {
    height: 10px;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    box-shadow: none;
    background-color: #fff;
}
.price-thumb {
    background-color: var(--qualimenti-color);
    width: 28px;
    height: 28px;
    border-radius: 24px;
    color: white;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: .7em;
}
.price-thumb:focus {
    outline: none;
    border: none;
}
.loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px);
    padding-top: 5%;
    display: flex;
    justify-content: center;
    z-index: 100;
}

.scrollBox {
    display: flex;
    align-items: center;
    justify-content: center;

}
.dark-link {
    text-decoration: none;
    color: initial;
}
.postcode-wrapper {
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    display: flex;
    justify-content: center;
    padding-top: 100px;
}
.footer-menu {
    padding:0;
    list-style-type: none;
}
.footer-menu-item a {
    color: #fff;
    text-decoration: none;
}
.qualimenti-link {
    color: var(--qualimenti-color);
    text-decoration: none;
}
.miniCart {
    margin-top: 10px;
    right: 10px;
    position: absolute;
    width: 300px;
    background-color: #fff;
    z-index: 100;
}
.miniCart-fixed {
    position: fixed;
    top: 10px;
    right: 10px;
    width: 300px;
    background-color: #fff;
    z-index: 100;
}
.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: 0;
}
.filter .form-check-input {
    border-radius: 10px;
}
.badge-brown {
    background-color: rgb(58,40,33)!important;
    color: #fff;
}
.filters.card {
    background: none;
    border: none;
    border-radius: 0;
    border-left: 4px solid rgb(58,40,33);
    color:rgb(58,40,33)
}
.filters.card .card-body {
    padding-top: 0;
    padding-bottom: 8px;
}
.filter-border {
    /* border-left: 4px solid rgb(58,40,33);
    padding-left: 10px; */
}
.filters .form-check-label {
    color: rgb(58,40,33);
}
hr.brown {
    border-top: 1px solid rgb(58,40,33);
}
.full-title {
    position: absolute;
    color: rgb(58,40,33)!important;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 250px;
    background-color: #ffffffc2;
    padding: 10px;
    font-weight: bold;
}

.fadeIn {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.fadeOut {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}
ul.footer-social {
    text-decoration: none;
    list-style-type: none;
    padding: 0;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    gap: 10px;
}

li.footer-social-item {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* width: 50px;
    height: 50px; */
    line-height: 25px;
    border-radius: 50%;
    color: rgb(58,40,33);
    font-size: 1.5em;
    background-color: #fff;
    cursor: pointer;
}
li.footer-social-item a {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    color: rgb(58,40,33);
}

.scrollTop {
    position: fixed;
    bottom: 0px;
    right: 10px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    box-shadow: 2px 2px 4px 1px rgba(51,51,51,0.5);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.removeOutline {
    outline: none;
}

.removeOutline:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #ced4da;
}
/* .sidebar-main-content ul {
    border-bottom: 1px solid #ddd;
    padding-top: 5px;
    padding-bottom: 5px;
} */
/* #react-sidebar .sidebar-main {
    width: 100%!important;
} */
svg.whiteicon path {
    stroke: #fff;
}
svg.redicon path {
    stroke: var(--qualimenti-color);
}
#react-sidebar > #sidebar-parent > .sidebar-main-content > .sidebar-body > ul > li {
    border-bottom: 1px solid #ddd;
    padding-top: 20px;
    padding-bottom: 20px;
}
.first-back-btn, .second-back-btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    color: #333;
    font-weight: 500;
    padding-top: 20px;
    padding-bottom: 20px;
    /* border-top: 1px solid #ddd; */
    border-bottom: 1px solid #ddd;
}
.second-back-btn {
    /* background-color: #962d2f95; */
}
.first-back-btn::before, .second-back-btn::before {
    content: '<';
    margin-right: 5px;
    font-weight: 500;
}
.first-back-btn svg, .second-back-btn svg {
    display: none;
}
.second-back-btn {
    margin: 0;
}
.truncate-2lines {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}
.paymentTabs .nav-link[aria-selected="false"] {
    color: var(--qualimenti-color);
}
.QualimentiModal .modal-content {
    border-radius: 27px;
}
.QualimentiModal .btn-close {
    position: absolute;
    top: 30px;
    right: 34px;
    font-size: .8em;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.25)!important;
}
@media (min-width: 600px) {
    .modal-dialog {
        max-width: 600px;
        margin-right: auto;
        margin-left: auto;
    }
}
@media (min-width: 768px) {
    .QualimentiModal .btn-close {
        top: 20px;
        right: 20px;
    }
}
select.noarrow {
    background: none !important;
}
select:focus {
    outline: none!important;
    box-shadow: none!important;
    border: 1px solid #333!important;
}
.rounded-step {
    aspect-ratio: 1/1;
    border-radius: 50%;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}